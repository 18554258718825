import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {User} from '../models/be/user';
import {LogService} from './log.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient,
        private logService: LogService
    ) {
    }

    private subscriptionGet: Subscription = new Subscription();

    post(obj: User): void {
        this.subscriptionGet = this.http.post<User>(`users`, User.asObjectUser(obj)).subscribe(u => {
            this.logService.updateLog(u, `users`, 'POST', User.asObjectUser(obj));
        }, error => {
            this.logService.updateLog(error.error.error.message, 'users', 'POST');
        });
    }

    ngOnDestroy() {
        this.subscriptionGet.unsubscribe();
    }
}
