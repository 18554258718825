import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserGroup} from '../../../core/models/fe/user-group';
import {FormGroup} from '@angular/forms';
import {Event} from '../../../core/models/be/event';
import {EpisodeService} from '../../../core/services/episode.service';
import {Episode} from '../../../core/models/fe/episode';

@Component({
    selector: 'app-episode',
    templateUrl: './episode.component.html',
    styleUrls: ['./episode.component.scss']
})
export class EpisodeComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public events: Event[] = [];

    constructor(
        private episodeService: EpisodeService
    ) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initSubscriptions();
    }

    initForm(): void {
        this.form = Episode.asFormGroup(new Episode());
    }

    initSubscriptions(): void {
        this.episodeService.getEvents().subscribe(e => this.events = e);
    }

    resetEvents(): void {
        this.episodeService.getEvents();
    }

    onFire(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.episodeService.post(this.form.value);
    }

    ngOnDestroy() {
        this.episodeService.ngOnDestroy()
    }
}
