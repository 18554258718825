import {NgModule} from '@angular/core';
import {UserComponent} from './pages/user/user.component';
import {InitComponent} from './pages/init/init.component';
import {UserGroupComponent} from './pages/user-group/user-group.component';
import {EpisodeComponent} from './pages/episode/episode.component';
import {DeepStreamComponent} from './pages/deep-stream/deep-stream.component';
import {LogComponent} from './pages/log/log.component';
import {SharedModule} from '../shared/shared.module';
import {EmsComponent} from './ems.component';
import {EmsRoutingModule} from './ems-routing.module';

@NgModule({
    declarations: [
        UserComponent,
        InitComponent,
        UserGroupComponent,
        EpisodeComponent,
        DeepStreamComponent,
        LogComponent,
        EmsComponent,
    ],
    imports: [
        SharedModule,
        EmsRoutingModule
    ],
    exports: [
        SharedModule
    ]
})
export class EmsModule {
}
