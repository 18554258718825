import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CookieService} from 'ngx-cookie-service';
import {SharedModule} from './shared/shared.module';
import {HttpClientModule} from '@angular/common/http';
import {EmsModule} from './ems/ems.module';
import {AuthModule} from './auth/auth.module';
import {CoreModule} from './core/core.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InitService} from './core/services/init.service';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        EmsModule,
        AuthModule,
        BrowserAnimationsModule,
        CoreModule
    ],
    providers: [
        CookieService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
