import {Login} from '../fe/login';
import {User as UserFE} from '../fe/user';
import {environment} from '../../../../environments/environment';

export class User {
    id?: number;
    email?: string;
    password?: string;
    apiKey?: string;
    sessionId?: string;
    firstName?: string;
    lastName?: string;
    tenantId?: number;

    static asObjectLogin(obj: Login): User {
        return {
            email: obj.email,
            password: obj.password,
            apiKey: environment.apiKey
        };
    }

    static asObjectUser(obj: UserFE): User {
        return {
            email: obj.email,
            password: obj.password,
            firstName: obj.firstName,
            lastName: obj.lastName,
        };
    }
}
