import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserGroupService} from '../../../core/services/user-group.service';
import {FormGroup} from '@angular/forms';
import {UserGroup} from '../../../core/models/fe/user-group';
import {Group} from '../../../core/models/be/group';
import {User} from '../../../core/models/be/user';

@Component({
    selector: 'app-user-group',
    templateUrl: './user-group.component.html',
    styleUrls: ['./user-group.component.scss']
})
export class UserGroupComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public users: User[] = [];
    public groups: Group[] = [];

    constructor(
        private userGroupService: UserGroupService
    ) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initSubscriptions();
    }

    initForm(): void {
        this.form = UserGroup.asFormGroup(new UserGroup());
    }

    initSubscriptions(): void {
        this.userGroupService.getUsers().subscribe(u => this.users = u);
        this.userGroupService.getGroups().subscribe(g => this.groups = g);
    }

    resetUsers(): void {
        this.userGroupService.getUsers();
    }

    resetGroups(): void {
        this.userGroupService.getGroups();
    }

    onCreate(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.userGroupService.post(this.form.value);
    }

    ngOnDestroy() {
        this.userGroupService.ngOnDestroy();
    }

}
