import {Episode} from '../fe/episode';

export class Event {
    id?: number;
    eventId?: number;
    name?: string;
    message?: string;

    static asObjectEpisode(obj: Episode): Event {
        return {
            message: obj.message,
            eventId: obj.eventId
        };
    }
}
