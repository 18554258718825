import {FormControl, FormGroup, Validators} from '@angular/forms';

export class UserGroup {
    userId: string;
    groupId: string;

    static asFormGroup(obj: UserGroup): FormGroup {
        return new FormGroup({
            userId: new FormControl(obj.userId, [Validators.required]),
            groupId: new FormControl(obj.groupId, [Validators.required]),
        });
    }
}
