import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Event} from '../models/be/event';
import {Episode} from '../models/fe/episode';
import {LogService} from './log.service';
import {isArray} from "util";

@Injectable({
    providedIn: 'root'
})
export class EpisodeService implements OnDestroy {

    constructor(
        private http: HttpClient,
        private logService: LogService
    ) {
    }

    private subscriptionGetEvents: Subscription = new Subscription();
    public eventsSubject: BehaviorSubject<Event[]> = new BehaviorSubject<Event[]>([]);

    getEvents(): BehaviorSubject<Event[]> {
        this.subscriptionGetEvents = this.http.get<Event[]>(`events?limit=1000000`).subscribe((data: Event[]) => {
            this.logService.updateLog(data, 'events');
            this.eventsSubject.next(isArray(data) ? data : []);
        }, error => {
            this.logService.updateLog(error.error.error.message, 'events');
        });
        return this.eventsSubject;
    }

    post(obj: Episode): void {
        this.http.post<void>(`events/${obj.eventId}/episodes`, Event.asObjectEpisode(obj)).subscribe(data => {
            this.logService.updateLog(data, `events/${obj.eventId}/episodes`, 'POST', Event.asObjectEpisode(obj));
        }, error => {
            this.logService.updateLog(error.error.error.message, `events/${obj.eventId}/episodes`, 'POST');
        });
    }

    ngOnDestroy() {
        this.subscriptionGetEvents.unsubscribe();
    }
}
