import {Injector, NgModule} from '@angular/core';
import {APP_INTERCEPTORS} from './interceptors';

export let AppInjector: Injector;

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        ...APP_INTERCEPTORS
    ]
})
export class CoreModule {
    constructor(private injector: Injector) {
        AppInjector = injector;
    }
}
