import {User} from '../be/user';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class Login {
    email: string;
    password: string;

    static asObjectUser(obj: User): Login {
        return {
            email: obj.email,
            password: obj.password,
        };
    }

    static asFormGroup(obj: Login): FormGroup {
        return new FormGroup({
            email: new FormControl(obj.email, [Validators.required]),
            password: new FormControl(obj.password, [Validators.required]),
        });
    }
}
