import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {MatRadioModule} from '@angular/material';
import {KeysPipe} from './pipes/keys.pipe';
import {MaterialModule} from './modules/material.module';
import {PrettyJsonModule} from 'angular2-prettyjson';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        ReactiveFormsModule,
        MatRadioModule,
        PrettyJsonModule
    ],
    declarations: [
        KeysPipe,
    ],
    exports: [
        KeysPipe,
        MaterialModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        MatRadioModule,
        PrettyJsonModule
    ],
})
export class SharedModule {
}
