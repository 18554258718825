import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EmsComponent} from './ems.component';

const routes: Routes = [
    {
        path: '',
        component: EmsComponent,
        children: [
            {
                path: '',
                redirectTo: 'init',
                pathMatch: 'full'
            },
            {
                path: 'init',
                component: EmsComponent
            },
            ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EmsRoutingModule {
}
