import {Component, OnDestroy, OnInit} from '@angular/core';
import {Login} from '../../../core/models/fe/login';
import {FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {LoginService} from '../../../core/services/login.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    public form: FormGroup;

    constructor(
        private loginService: LoginService
    ) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    initForm(): void {
        this.form = Login.asFormGroup(new Login());
    }

    onLogin(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.loginService.post(this.form.value);
    }

    ngOnDestroy() {
        this.loginService.ngOnDestroy();
    }
}
