import {Component, OnInit} from '@angular/core';
import {DeepstreamClient} from '@deepstream/client';
import {environment} from '../../../../environments/environment';
import {LoginService} from "../../../core/services/login.service";
import {Login} from "../../../core/models/fe/login";

@Component({
    selector: 'app-deep-stream',
    templateUrl: './deep-stream.component.html',
    styleUrls: ['./deep-stream.component.scss']
})
export class DeepStreamComponent implements OnInit {
    tpl: string;
    timeState = Date.now();
    timeEvent = Date.now();
    loginSubject = new Login();

    constructor(public loginService: LoginService) {
    }

    ngOnInit() {
        this.loginService.loginSubject.subscribe(x => this.loginSubject = x);
        this.connectDeepStream(this.loginSubject);
    }

    logTime(lastTime: number): string {
        const time = Date.now();
        let diff = ((time - lastTime) / 1000).toString();
        diff = '' + diff;
        return time + ' (' + diff.substring(0, 5) + ') ';
    }

    connectDeepStream(login: Login) {
        const client = new DeepstreamClient('wss://staging-ds.instasolution.ch:443');
        const loginObj = Login.asObjectUser(login);
        if (!loginObj.email) {
          this.tpl += 'Error, could not login to deepstream. Check accounts.json from mock_auth_service.';
          return;
        }

        this.tpl = `
        ***************************
        * DeepStream channel Montior
        * have fun watching what is going on :3
        ***************************
        `;

        client.login({
            apiKey: environment.apiKey,
            email: loginObj.email,
            password: loginObj.password
        }, (success: boolean, data: any) => {
            if (success) {
                console.log('Logged in with ' + data.sessionId);
                client.event.subscribe(data.deepstreamChannelTarget, (notification) => {
                    this.tpl += this.logTime(this.timeEvent) + '\n[EVENT] \n' + JSON.stringify(notification, null, 2);
                    this.timeEvent = Date.now();
                });
                this.tpl += this.logTime(this.timeEvent) + '\n[WATCH] \n' + data.deepstreamChannelTarget;
                this.timeEvent = Date.now();
            } else {
                this.tpl += 'Error, could not login to deepstream. Check accounts.json from mock_auth_service.';
                process.exit(1);
            }
        });
    }
}
