import {Component, OnInit} from '@angular/core';
import {LogService} from '../../../core/services/log.service';

@Component({
    selector: 'app-log',
    templateUrl: './log.component.html',
    styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {

    constructor(
        private logService: LogService
    ) {
    }

    public customCollapsedHeight = '35px';
    public customExpandedHeight = '35px';
    public logs: any;

    ngOnInit() {
        this.logService.logSubject.subscribe(l => {
            this.logs = l;
        })
    }

}
