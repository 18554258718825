import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private cookieService: CookieService
    ) {
    }

    static adjustUrl(url: string): string {
        url = url.replace(new RegExp('#', 'g'), '');
        if (url.indexOf('http://') >= 0 || url.indexOf('https://') >= 0) {
            return url;
        }
        return environment.apiUrl + url;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            url: AuthInterceptor.adjustUrl(request.url),
            setHeaders: {
                sessionId: this.getSessionId(),
                'Content-Type': 'multipart/form-data'
            }
        });

        return next.handle(request);
    }

    getSessionId(): string {
        const sessionId = this.cookieService.get('sessionId');
        if (sessionId) {
            return sessionId;
        }
        return '';
    }
}
