import {Component, OnDestroy, OnInit} from '@angular/core';
import {InitService} from '../../../core/services/init.service';

@Component({
    selector: 'app-init',
    templateUrl: './init.component.html',
    styleUrls: ['./init.component.scss']
})
export class InitComponent implements OnInit, OnDestroy {

    constructor(
        private initService: InitService
    ) {
    }

    ngOnInit() {
    }

    onInit(): void {
        this.initService.post();
    }


    ngOnDestroy() {

    }
}
