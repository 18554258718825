import {FormControl, FormGroup, Validators} from '@angular/forms';

export class Episode {
    eventId: number;
    message: string;

    static asFormGroup(obj: Episode): FormGroup {
        return new FormGroup({
            eventId: new FormControl(obj.eventId, [Validators.required]),
            message: new FormControl(obj.message, [Validators.required]),
        });
    }
}
