import {User as UserBE} from '../be/user';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class User {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;

    static asObjectUser(obj: UserBE): User {
        return {
            firstName: obj.firstName,
            lastName: obj.lastName,
            email: obj.email,
            password: obj.password,
        };
    }

    static asFormGroup(obj: User): FormGroup {
        return new FormGroup({
            email: new FormControl(obj.email, [Validators.required]),
            password: new FormControl(obj.password, [Validators.required]),
            firstName: new FormControl(obj.firstName, [Validators.required]),
            lastName: new FormControl(obj.lastName, [Validators.required]),
        });
    }
}
