import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {UserService} from '../../../core/services/user.service';
import {User} from '../../../core/models/fe/user';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
    public form: FormGroup;

    constructor(
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    initForm(): void {
        this.form = User.asFormGroup(new User());
    }

    onCreate(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.userService.post(this.form.value);
    }

    ngOnDestroy() {
        this.userService.ngOnDestroy();
    }
}
