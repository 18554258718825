import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Login} from '../models/fe/login';
import {User} from '../models/be/user';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {User as UserFE} from '../models/fe/user';

@Injectable({
    providedIn: 'root'
})
export class LoginService implements OnDestroy {

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private router: Router,
    ) {
    }

    private subscriptionGet: Subscription = new Subscription();
    public loginSubject: BehaviorSubject<Login> = new BehaviorSubject<Login>(new Login());

    post(obj: Login): void {
        this.loginSubject.next(Login.asObjectUser(obj));
        this.http.post<User>(`system/session?embedded=user`, User.asObjectLogin(obj))
            .subscribe((obj: any) => {
                if (obj.sessionId) {
                    const secure = true;
                    const sameSite = 'Strict';
                    this.cookieService.set('sessionId', obj.sessionId, null, '/', null, secure, sameSite);
                    this.cookieService.set('tenantId', obj.user.tenantId.toString(), null, '/', null, secure, sameSite);
                    this.router.navigate([`/init`]).then();
                }
            });
    }
    ngOnDestroy() {
        this.subscriptionGet.unsubscribe();
    }
}
