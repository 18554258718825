import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LogService {

    constructor() {
    }

    public logSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    public updateLog(response: any, url: string, type = 'GET', params: any = {}): void {
        const log = this.logSubject.getValue();
        response = response ? response: {};
        log.push({
            response: JSON.stringify(response, null, 3),
            url: environment.apiUrl + url,
            params: JSON.stringify(params, null, 3),
            type
        })
        this.logSubject.next(log);
    }
}
