import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LogService} from './log.service';
import {Subscription} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class InitService implements OnDestroy {

    constructor(
        private http: HttpClient,
        private logService: LogService,
        private cookieService: CookieService
    ) {
    }

    private subscriptionGet: Subscription = new Subscription();

    post(): void {
        const tenantId = this.cookieService.get('tenantId');

        this.subscriptionGet = this.http.post<void>(`initial/data`, {tenantId: tenantId})
            .subscribe(u => {
        }, error => {
        });
    }

    ngOnDestroy() {
        this.subscriptionGet.unsubscribe();
    }
}
