import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Subscription} from 'rxjs';
import {User} from '../models/be/user';
import {User as UserFE} from '../models/fe/user';
import {Group} from '../models/be/group';
import {LogService} from './log.service';
import {isArray} from 'util';

@Injectable({
    providedIn: 'root'
})
export class UserGroupService implements OnDestroy {

    constructor(
        private http: HttpClient,
        private logService: LogService
    ) {
    }

    private subscriptionGetUsers: Subscription = new Subscription();
    private subscriptionGetGroups: Subscription = new Subscription();
    private subscriptionPost: Subscription = new Subscription();
    public usersSubject: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
    public groupsSubject: BehaviorSubject<Group[]> = new BehaviorSubject<Group[]>([]);

    getUsers(): BehaviorSubject<User[]> {
        this.subscriptionGetUsers = this.http.get<User[]>(`users?scope=profile&limit=1000000`).subscribe((data: User[]) => {
            this.logService.updateLog(data, 'users');
            this.usersSubject.next(isArray(data) ? data : []);
        }, error => {
            this.logService.updateLog(error.error.error.message, 'users');
        });
        return this.usersSubject;
    }

    getGroups(): BehaviorSubject<Group[]> {
        this.subscriptionGetGroups = this.http.get<Group[]>(`groups?limit=1000000`).subscribe((data: Group[]) => {
            this.logService.updateLog(data, 'groups');
            this.groupsSubject.next(isArray(data) ? data : []);
        }, error => {
            this.logService.updateLog(error.error.error.message, 'groups');
        });
        return this.groupsSubject;
    }

    post(obj: any): void {
        this.subscriptionPost = this.http.post<void>(`groups/${obj.groupId}/user`, {userId: obj.userId})
            .subscribe(u => {
                this.logService.updateLog(u, `groups/${obj.groupId}/user`, 'POST', {userId: obj.userId})
            }, error => {
                this.logService.updateLog(error.error.error.message, `groups/${obj.groupId}/user`, 'POST');
            });
    }

    ngOnDestroy() {
        this.subscriptionGetGroups.unsubscribe();
        this.subscriptionGetUsers.unsubscribe();
        this.subscriptionPost.unsubscribe();
    }
}
